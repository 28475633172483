<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col sm="6" cols="12" md="6" lg="3" v-if="isAdmin">
        <router-link tag="a" to="/timesheets/review">
          <material-stats-card
            color="#156a59"
            icon="mdi-domain"
            title="Timesheets"
            value=""
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>

      <v-col sm="6" cols="12" md="6" lg="3">
        <router-link tag="a" to="/my-timesheet">
          <material-stats-card
            color="#156a59"
            icon="mdi-receipt"
            title="My timesheet"
            value=""
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" v-if="isAdmin">
        <router-link tag="a" to="/timesheets/report">
          <material-stats-card
            color="#156a59"
            icon="mdi-file-chart"
            title="Timesheet reporting"
            value=""
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" >
        <router-link tag="a" to="/timesheets/my-timesheet-hours-report">
          <material-stats-card
            color="#156a59"
            icon="mdi-file-chart"
            title="My timesheet hours report"
            value=""
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.v-card--material-stats:hover {
  border-top: 4px solid #156a59;
}
</style>
<script>
export default {

  data: () => ({
    vm: {},

    isAdmin: false
  }),
  computed: {
    
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    
      if (
        this.user.roles.indexOf("System Administrator") > -1 ||
        this.user.roles.indexOf("Organisation Administrator") > -1
      ) {
        this.isAdmin = true;
      }
      this.countAlerts();
    },
  }
};
</script>
